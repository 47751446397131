<template>
  <div class="tw-flex tw-flex-col tw-space-y-6">
    <div v-if="!isLoading">
      <CalendarService
        v-for="service in services"
        :key="service.id"
        :service="service"
        :integrated-calendars="calendarServices"
        class="tw-mb-4"
        @disconnected="calendar => $emit('disconnected', calendar)"
        @synchronization-created="$emit('synchronization-created')"
      />
    </div>
    <Loading v-else loader="dots" :is-full-page="false" :active="isLoading" />
  </div>
</template>

<script>
import CalendarService from '@/components/calendar-services/CalendarService'
import googleCalendarLogo from '@/assets/img/google-calendar-logo.png'
import outlookCalendarLogo from '@/assets/img/outlook-calendar-logo.png'
import ExternalCalendars from '@/api/externalcalendars/ExternalCalendars'
import EmploymentExternalCalendarCollection from '@/models/Synchronizations/EmploymentExternalCalendarCollection'
import Loading from 'vue-loading-overlay'

export default {
  name: 'CalendarServices',

  components: {
    Loading,
    CalendarService,
  },

  props: {
    calendarServices: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isLoading: true,
      employmentExternalCalendars: new EmploymentExternalCalendarCollection(),
    }
  },

  computed: {
    services() {
      const googleAccounts =
        this.employmentExternalCalendars.findByService('google_calendar')
          ?.accounts ?? []

      const outlookAccounts =
        this.employmentExternalCalendars.findByService('outlook_calendar')
          ?.accounts ?? []

      return [
        {
          id: 'google_calendar',
          name: 'Google Calendar',
          logo: googleCalendarLogo,
          accounts: googleAccounts,
        },
        {
          id: 'outlook_calendar',
          name: 'Outlook Calendar',
          logo: outlookCalendarLogo,
          accounts: outlookAccounts,
        },
      ]
    },
  },

  created() {
    this.fetchEmploymentExternalCalendars()
  },

  methods: {
    async fetchEmploymentExternalCalendars() {
      this.employmentExternalCalendars = await ExternalCalendars.getEmploymentExternalCalendars(
        this.activeEmployment.id
      )

      this.isLoading = false
    },
  },
}
</script>
