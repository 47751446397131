export default class EmploymentExternalCalendarCollection {
  items = []

  constructor(data = []) {
    this.items = data
  }

  findByService(service) {
    return this.items.find(calendars => calendars.service === service)
  }
}
