<template>
  <tr>
    <td>{{ companyCalendar.serviceName }}</td>
    <td>{{ companyCalendar.email }}</td>
    <td>{{ companyCalendar.calendarName }}</td>
    <td>{{ companyCalendar.scopeNames }}</td>
    <td style="width: 300px;" class="tw-text-center">
      <div v-if="companyCalendar.service_error" class="tw-flex tw-items-center">
        <div
          class="tw-inline-flex tw-bg-red-200 tw-rounded-full tw-text-center tw-px-5 tw-py-1"
        >
          <span class="tw-text-red-500 tw-font-semibold">Connection error</span>
        </div>
        <div class="tw-inline-flex tw-px-5 tw-py-1">
          <a
            title="Fix the connection error"
            class="btn-link tw-text-blue-500 tw-font-semibold"
            @click="$emit('reconnect', companyCalendar)"
            >Reconnect</a
          >
        </div>
      </div>
      <div
        v-if="!companyCalendar.service_error"
        class="tw-inline-flex tw-bg-green-200 tw-rounded-full tw-text-center tw-px-5 tw-py-1"
      >
        <span class="tw-text-green-500 tw-font-semibold">Connected</span>
      </div>
    </td>
    <td style="width: 100px;">
      <div class="tw-flex tw-items-center tw-justify-center">
        <button
          class="btn btn-icon tw-border tw-border-gray-400 hover:tw-bg-gray-300 tw-ml-3"
          :title="
            companyCalendar.service_error
              ? 'Please reconnect the company calendar before editing or deleting it, as we need permission to update the old entries.'
              : 'Edit'
          "
          :disabled="companyCalendar.service_error"
          type="button"
          :style="
            companyCalendar.service_error
              ? 'pointer-events: auto; cursor: default'
              : ''
          "
          @click.stop="$emit('edit-calendar')"
        >
          <div class="tw-flex tw-items-center tw-justify-center">
            <SvgIcon name="edit-pencil" class="svg-icon tw-text-gray-700" />
          </div>
        </button>
        <button
          class="btn btn-icon tw-border tw-bg-red-500 hover:tw-bg-red-500 tw-border-red-500 tw-ml-3"
          :title="'Delete'"
          type="button"
          @click="$emit('delete-calendar')"
        >
          <div class="tw-flex tw-items-center tw-justify-center">
            <SvgIcon
              name="trash"
              class="svg-icon tw-w-3 tw-h-3 tw-text-white"
            />
          </div>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'CompanyCalendarTableRow',

  props: {
    companyCalendar: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hoverRedirectionUrl: false,
      hoverCallbackUrl: false,
    }
  },

  methods: {
    redirectionUrlCopied() {
      this.success('Redirection url successfully copied to the clipboard.')
    },

    callbackUrlCopied() {
      this.success('Callback url successfully copied to the clipboard.')
    },
  },
}
</script>
