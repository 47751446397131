<template>
  <Banner
    theme="orange"
    icon="information-outline"
    class="tw-mt-4 tw-mx-4"
    @close="close"
  >
    <template v-slot:title>Reconnect your account</template>

    <p class="tw-align-top">
      The connection to
      <span class="tw-font-semibold">{{ calendar.email }}</span>
      {{ calendar.name.toLowerCase() }} calendar has broken.
    </p>

    <div class="tw-mt-2">
      <CalendarServiceConnector
        v-slot="{ reconnect, disconnect }"
        @disconnected="refreshErrorCalendarServices"
      >
        <div>
          <button
            class="tw-px-5 tw-py-1 tw-rounded-full tw-font-semibold tw-text-sm tw-text-orange-100 hover:tw-text-white tw-bg-orange-500 tw-cursor-pointer"
            @click="reconnect(calendar)"
          >
            Reconnect
          </button>

          <button
            class="tw-ml-4 tw-font-semibold tw-text-sm tw-text-orange-500 hover:tw-text-orange-500 tw-underline tw-cursor-pointer"
            @click="disconnect(calendar)"
          >
            Disconnect
          </button>
        </div>
      </CalendarServiceConnector>
    </div>
  </Banner>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import CalendarServiceConnector from '@/components/calendar-services/CalendarServiceConnector'
import Banner from '@/components/Banner'

export default {
  name: 'CalendarServiceReConnector',

  components: { Banner, CalendarServiceConnector },

  props: {
    calendar: {
      type: Object,
      required: true,
    },
  },

  methods: {
    ...mapMutations('auth', ['markCalendarErrorAsDismissed']),

    ...mapActions('auth', ['fetchUser']),

    close() {
      this.markCalendarErrorAsDismissed({
        activeEmployment: this.activeEmployment,
        dismissingCalendar: this.calendar,
      })
    },

    refreshErrorCalendarServices() {
      this.fetchUser()
    },
  },
}
</script>
