import http from '@/plugins/http'

export default class EmploymentCalendar {
  static reconnect(employment, payload) {
    return http.post(
      `employments/${employment.id}/synchronizations/${payload.group}/reconnect`,
      payload
    )
  }

  static create(employment, payload) {
    return http.post(`employments/${employment.id}/synchronizations`, payload)
  }

  static disconnect(employment, group, payload) {
    return http.delete(
      `employments/${employment.id}/synchronizations/${group}`,
      payload
    )
  }
}
