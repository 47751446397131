import http from '@/plugins/http'
import EmploymentExternalCalendarCollection from '@/models/Synchronizations/EmploymentExternalCalendarCollection'

export default class ExternalCalendars {
  static async updateCompanyCalendar(company, group, params) {
    await http.put(`companies/${company.id}/synchronizations/${group}`, params)
  }

  static deleteCalendar(company, calendar) {
    return http.delete(
      `companies/${company.id}/synchronizations/${calendar.group}`
    )
  }

  static reconnect(company, calendar) {
    return http.post(
      `companies/${company.id}/synchronizations/${calendar.group}/reconnect`,
      {
        company_id: company.id,
      }
    )
  }

  static async getSynchronizations(company) {
    const { data } = await http.get(`companies/${company.id}/synchronizations`)

    return data
  }

  static async getEmploymentExternalCalendars(employmentKey) {
    const { data } = await http.get(
      `employments/${employmentKey}/external-calendars`
    )

    return new EmploymentExternalCalendarCollection(data)
  }

  static async create(companyId, params) {
    return http.post(`companies/${companyId}/synchronizations`, params)
  }
}
