<script>
import ValidatesForm from '@/mixins/ValidatesForm'
import EmploymentCalendar from '@/api/synchronizations/EmploymentCalendar'
import OAuthProvider from '@/api/oauth/OAuthProvider'

export default {
  name: 'CalendarServiceConnector',

  mixins: [ValidatesForm],

  methods: {
    async connect(service) {
      try {
        const { data } = await OAuthProvider.redirect(service, {
          company_id: this.activeCompany.id,
          ownable_type: 'employment',
        })

        window.location = data.redirect_url
      } catch ({ response }) {
        this.validateFromResponse(response)
      }
    },

    async reconnect(calendar) {
      try {
        const { data } = await EmploymentCalendar.reconnect(
          this.activeEmployment,
          {
            company_id: this.activeCompany.id,
            group: calendar.group,
          }
        )

        window.location = data.redirect_url
      } catch ({ response }) {
        this.validateFromResponse(response)
      }
    },

    async disconnect(calendar) {
      const confirm = await this.confirmCalendarDisconnect(calendar)
      if (!confirm) return

      try {
        await EmploymentCalendar.disconnect(
          this.activeEmployment,
          calendar.group,
          {
            data: {
              company_id: this.activeCompany.id,
            },
          }
        )

        this.$emit('disconnected', calendar)

        this.success(`${calendar.name} calendar disconnected`)
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async create(service, account) {
      try {
        await EmploymentCalendar.create(this.activeEmployment, {
          company_id: this.activeCompany.id,
          service,
          account,
        })
      } catch ({ response }) {
        this.validateFromResponse(response)
      }
    },

    async confirmCalendarDisconnect(calendar) {
      if (calendar.has_connection_error) {
        return await this.confirm(
          'Are you sure you want to disconnect? There are pending synchronisation jobs for this calendar. ' +
            'If you remove the integration now, we will not be able to sync the latest changes, ' +
            'which could result in problems such as duplicate events.',
          'Confirm delete'
        )
      }

      return await this.confirm(
        `Are you sure you want to disconnect? Leave will be removed from the ${calendar.name} calendar.`,
        'Confirm disconnect'
      )
    },
  },

  render() {
    return this.$scopedSlots.default({
      connect: this.connect,
      reconnect: this.reconnect,
      disconnect: this.disconnect,
      create: this.create,
    })
  },
}
</script>
