<template>
  <div class="tw-flex tw-items-center tw-justify-start">
    <div class="tw-mr-6">
      <img
        :src="service.logo"
        class="tw-w-16"
        style="min-width: 4rem;"
        :alt="service.name"
      />
    </div>

    <div>
      <div class="tw-text-lg tw-text-gray-800 tw-mb-2 tw-font-semibold">
        {{ service.name }}
      </div>

      <template v-if="isSuccessfullyIntegrated" class="tw-flex">
        <div class="tw-flex tw-justify-between">
          <SvgIcon
            name="check-circle-solid"
            class="tw-w-5 tw-h-5 tw-text-green-500"
            style="min-width: 1.25rem;"
          />

          <div class="tw-ml-2 tw-break-all">
            Connected with
            <span class="tw-font-semibold">{{ integratedCalendar.email }}</span>
          </div>
        </div>
      </template>

      <div class="tw-mt-2">
        <CalendarServiceConnector
          v-slot="{ connect, reconnect, disconnect, create }"
          @disconnected="calendar => $emit('disconnected', calendar)"
        >
          <div class="tw-flex">
            <vSelect
              v-if="!integratedCalendar && accounts.length"
              id="account"
              v-model="account"
              v-validate="'required'"
              :options="accounts"
              :show-labels="false"
              :tabindex="2"
              :allow-empty="false"
              :max-height="180"
              placeholder="Select an account"
              name="account"
              class="tw-mr-3"
            >
            </vSelect>
            <button
              v-if="isSuccessfullyIntegrated"
              type="button"
              class="btn-link"
              @click="disconnect(integratedCalendar)"
            >
              Disconnect
            </button>

            <div v-if="isIntegratedWithErrors">
              <div
                class="tw-flex tw-justify-between tw-text-orange-400 tw-mt-1"
              >
                <SvgIcon
                  name="information-outline"
                  class="tw-w-5 tw-h-5"
                  style="min-width: 1.25rem;"
                />

                <div class="tw-ml-2 tw-break-words">
                  The connection to
                  <span class="tw-font-semibold">{{
                    integratedCalendar.email
                  }}</span>
                  has broken
                </div>
              </div>
              <button
                type="button"
                class="btn-link tw-mt-2"
                @click="reconnect(integratedCalendar)"
              >
                Reconnect
              </button>
              <button
                type="button"
                class="btn-link tw-mt-2 tw-ml-5"
                @click="disconnect(integratedCalendar)"
              >
                Disconnect
              </button>
            </div>

            <SpinnerButton
              v-if="!integratedCalendar"
              :disabled="!valid"
              :spinner-classes="['tw-h-2 tw-w-2 tw-text-blue-500']"
              :loading="isCreating"
              :spinner-only="true"
              type="button"
              as="link"
              class="tw-p-0"
              @click="connectOrCreate(connect, create)"
            >
              Connect
            </SpinnerButton>
          </div>
        </CalendarServiceConnector>
      </div>
    </div>
  </div>
</template>

<script>
import { find } from 'lodash-es'
import CalendarServiceConnector from '@/components/calendar-services/CalendarServiceConnector'
const vSelect = () => import('vue-multiselect')
import ValidatesForm from '@/mixins/ValidatesForm'
import SpinnerButton from '@/components/SpinnerButton'

export default {
  name: 'CalendarService',

  components: { SpinnerButton, CalendarServiceConnector, vSelect },

  mixins: [ValidatesForm],

  props: {
    service: {
      type: Object,
      required: true,
    },

    integratedCalendars: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      account: '',
      isCreating: false,
    }
  },

  computed: {
    accounts() {
      if (!this.service.accounts.length) {
        return []
      }

      return ['Add new account', ...this.availableAccounts]
    },

    integratedCalendar() {
      return (
        find(this.integratedCalendars, { service: this.service.id }) || null
      )
    },

    isSuccessfullyIntegrated() {
      return (
        !!this.integratedCalendar &&
        !this.integratedCalendar.has_connection_error
      )
    },

    isIntegratedWithErrors() {
      return (
        !!this.integratedCalendar &&
        this.integratedCalendar.has_connection_error
      )
    },

    isCreatingToAccount() {
      return this.availableAccounts.includes(this.account)
    },

    availableAccounts() {
      if (!this.service.accounts.length) {
        return []
      }

      return this.service.accounts.map(account => account.account)
    },
  },

  methods: {
    async connectOrCreate(connect, create) {
      await this.validate()

      this.isCreating = true

      if (this.isCreatingToAccount) {
        await create(this.service.id, this.account)

        this.$emit('synchronization-created')
        this.isCreating = false

        return
      }

      await connect(this.service.id)

      this.isCreating = false
    },
  },
}
</script>
