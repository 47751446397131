import http from '@/plugins/http'

export default class OAuthProvider {
  static async redirect(service, params) {
    const serviceProviderMapper = {
      google_calendar: 'google',
      outlook_calendar: 'outlook',
    }

    const provider = serviceProviderMapper[service]

    return http.post(`oauth/${provider}/redirect`, params)
  }
}
